<template>
  <v-dialog v-model="show" :max-width="500" scrollable hide-on-leave>
    <template v-if="showActivator" v-slot:activator="{ on, attrs }">
      <v-btn :text="!minimal" color="red lighten-2" dark v-bind="attrs" v-on="on" :icon="minimal">
        <span v-if="!minimal"> <v-icon left>mdi-plus-circle</v-icon>Medications </span>
        <v-icon v-if="minimal">mdi-plus-circle-outlind</v-icon>
      </v-btn>

      <!-- <v-list-item
            
            v-if="listItem"
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-content>
<v-list-item-title class="font-weight-medium text-subtitle-2 text--disabled">
    Update Conditions
</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <v-icon color="primary" >mdi-plus-circle</v-icon>
            </v-list-item-action>
      </v-list-item>-->
    </template>
    <v-card min-height="300" class="pa-3 px-4">
      <v-row align="center" no-gutters justify="space-between" class="font-weight-bold text-h6">
        <span v-if="patient && patient.firstName && patient.lastName"
          >{{ patient.firstName }} {{ patient.lastName }}'s Medications</span
        >
        <!-- <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>-->
      </v-row>

      <v-autocomplete
        hide-details
        :allow-overflow="false"
        class="mt-3"
        ref="search"
        type="search"
        solo
        background-color="grey lighten-3"
        flat
        :menu-props="{ offsetOverflow: false, maxWidth: 468 }"
        prepend-inner-icon="mdi-magnify"
        autocomplete="off"
        clearable
        v-model="selectedMedication"
        return-object
        item-text="name"
        :items="medicationOptions"
        :search-input.sync="medicationSearch"
        placeholder="Medication Name"
      >
        <template v-slot:no-data>
          <v-card-text v-if="lookingUpMedication">Processing</v-card-text>
          <v-card-text v-else-if="!medicationSearch || medicationSearch.length === 0">Search by drug name</v-card-text>
          <v-card-text v-else>No matching Results</v-card-text>
        </template>
      </v-autocomplete>

      <v-select
        hide-details
        class="mt-4"
        solo-inverted
        flat
        v-model="selectedStrength"
        :disabled="!selectedMedication || strengthOptions.length === 0"
        :items="strengthOptions"
        placeholder="Strength"
      />

      <v-card min-height="150" flat color="transparent">
        <v-row class="mt-4" v-if="patient" no-gutters>
          <v-chip
            class="mr-2 mb-2"
            label
            color="primary"
            v-for="(item, index) in medicationsArray"
            :key="index"
            close
            @click:close="removeMedication(item)"
          >
            <span class="d-inline-block text-truncate" style="max-width: 400px">{{ item.name }}</span>
          </v-chip>
        </v-row>
      </v-card>

      <v-row no-gutters class="mx-0 mt-4">
        <!-- Buttons -->
        <v-btn depressed @click="cancelOption()">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="selectedMedication === null" depressed @click="cancelOption()">Done</v-btn>

        <v-btn v-if="selectedMedication != null" depressed dark color="green" @click="addMedication"
          >Add Medication</v-btn
        >
      </v-row>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>

<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import { mapActions, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import { mask } from 'vue-the-mask'
import bus from '@/core/helpers/bus'
import nih from '@/helpers/nih'

export default {
  name: 'PatientMedicationsDialog',
  mixins: [DialogMixin, HelperMixin],
  components: {},
  directives: {
    mask,
  },
  props: {
    patient: {
      type: Object,
      default: null,
    },
    minimal: {
      type: Boolean,
      deafult: false,
    },
    listItem: {
      type: Boolean,
      deafult: false,
    },
    showActivator: {
      type: Boolean,
      deafult: true,
    },
  },
  data() {
    return {
      showLookup: false,
      show: false,
      medicationSearch: null,
      medicationOptions: [],
      strengthOptions: [],
      selectedMedication: null,
      selectedStrength: null,
      mendozaLine: 0,
      lookingUpMedication: false,
    }
  },
  watch: {
    showLookup(val) {
      if (val) {
        this.$nextTick(() => this.$refs.search.activateMenu())
      }
    },
    async medicationSearch(val) {
      if (val) {
        this.lookingUpMedication = true
        this.medicationOptions = await nih.lookupMed(val)
        this.lookingUpMedication = false
      }
    },
    selectedMedication(val) {
      if (val) {
        this.strengthOptions = val.strengths
        if (this.strengthOptions.length > 0) {
          this.selectedStrength = val.strengths[0]
        }
      } else {
        this.strengthOptions = []
      }
    },
  },
  methods: {
    ...mapActions('auth', ['userActivity']),
    ...mapActions('patient', ['addNote', 'update']),
    // for when we show the short list of conditions
    chipColor(tag) {
      if (tag.applied) {
        return 'primary'
      }
      return null
    },
    addMedication() {
      this.$refs.search.blur()
      let medication = {}
      medication.compound = this.selectedMedication.name + '|' + this.selectedStrength
      let medicationList = this.patient.medications
      let canPush = true
      medicationList.forEach(element => {
        if (element === medication.compound) {
          canPush = false
        }
      })
      if (canPush) {
        medicationList.push(medication.compound)
        this.mendozaLine = this.mendozaLine + 1
        this.update({ payload: { medications: { value: medicationList } } })
        // prepare the med object to attach to a chart note
        let objectProto = medication.compound.split('|')
        let medicationObject = {}
        medicationObject.name = objectProto[0]
        medicationObject.displayName = objectProto[0]
        if (objectProto[1]) {
          medicationObject.strength = objectProto[1]
          medicationObject.displayName = medicationObject.displayName + ' (' + objectProto[1] + ')'
        }
        medicationObject.fullItem = medication.compound
        this.medicationOptions = []
        this.selectedMedication = null
        this.strengthOptions = []
        bus.$emit('toast', { type: 'success', text: 'Medication Added' })
        // this.addNote({
        //   content: 'Updated medication list.',
        //   type: 'auto',
        //   action: 'add',
        //   referenceData: { type: 'medication', data: medicationObject },
        // })

        this.userActivity({
          patient: this.$route.params.id,
          type: 'update_medication_list',
        })
      }
    },
    removeMedication(medication) {
      let medicationList = this.patient.medications
      let index = medicationList.indexOf(medication.fullItem)
      if (index > -1) {
        medicationList.splice(index, 1)
      }
      this.update({ payload: { medications: { value: medicationList } } })
      bus.$emit('toast', { type: 'error', text: 'Medication Removed' })
      this.addNote({
        content: 'Updated medication list.',
        type: 'auto',
        action: 'remove',
        referenceData: { type: 'medication', data: medication },
      })

      this.userActivity({
        patient: this.$route.params.id,
        type: 'update_medication_list',
      })
    },
    onClose() {},
    cancelOption() {
      this.medicationOptions = []
      this.selectedMedication = null
      this.strengthOptions = []
      this.cancel()
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    // get the full list of conditions fron the taskboard
    medicationsArray() {
      let medications = []
      if (this.patient && this.patient.medications) {
        this.patient.medications.forEach(medication => {
          let objectProto = medication.split('|')
          let medicationObject = {}
          medicationObject.name = objectProto[0]
          medicationObject.displayName = objectProto[0]
          if (objectProto[1]) {
            medicationObject.strength = objectProto[1]
            medicationObject.displayName = medicationObject.displayName + ' (' + objectProto[1] + ')'
          }
          medicationObject.fullItem = medication
          medications.push(medicationObject)
        })
      }
      return medications
    },
    submitTitle() {
      return 'Add'
    },
  },
  mounted() {
    this.$on('open', this.onOpen)
    this.$on('close', this.onClose)
  },
}
</script>
