import axios from 'axios'
import parseUrl from '@/core/helpers/parseUrl'

async function lookupCondition(terms) {
  const url = parseUrl('https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?terms&maxList', { terms, maxList: 10 }) + '&sf=code,name'
  const { data } = await axios.get(url)
  const conditions = []
  for (let i = 0; i < data[1].length; i++) {
    const code = data[1][i]
    const name = data[3][i][1]
    conditions.push({
      name: name,
      code: code,
      description: code + ' ' + name,
      compound: code + '|' + name,
    })
  }
  return conditions
}

async function lookupMed(terms) {
  const url = parseUrl('https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms&maxList', { terms, maxList: 10 }) + '&ef=STRENGTHS_AND_FORMS'
  const { data } = await axios.get(url)
  const meds = []
  for (let i = 0; i < data[1].length; i++) {
    const name = data[1][i]
    meds.push({
      name: name,
      text: name,
      strengths: data[2].STRENGTHS_AND_FORMS[i],
    })
  }
  return meds
}

export default {
  lookupCondition,
  lookupMed
}