function parseParams(url, hash) {
  for (const e in hash) {
    const regExp = new RegExp(':(' + e + ')\\b', 'g')
    if (regExp.test(url)) {
      url = url.replace(regExp, hash[e])
      delete hash[e]
    }
  }
  url = url.replace(/\/:\w+/gi, '') // strip any "/:ids" that were still remaining
  url = url.replace(/\/$/gi, '') // strip trailing slash "/"
  return url
}

function parseQuery(url, hash) {
  const parts = url.split('?')
  const baseUrl = parts.shift()
  const query = parts.pop()
  if (!query) {
    return url
  }
  const queryParams = []
  const params = query.split('&')
  for (let i = 0; i < params.length; i++) {
    const param = params[i]
    if (hash[param] !== undefined) {
      queryParams.push(param + '=' + encodeURIComponent(hash[param]))
    }
  }
  return baseUrl + '?' + queryParams.join('&')
}

function parseUrl(url, hash) {
  url = parseParams(url, hash)
  url = parseQuery(url, hash)
  return url
}

export default parseUrl
