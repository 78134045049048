<template>
  <v-card rounded="lg" class="flex pa-2 py-1" flat color="grey lighten-4">
    <v-row no-gutters align="center" justify="space-between">
      <patient-action-bar-export-facesheet-menu />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-1" @click="handleMessengerClick" v-bind="attrs" v-on="on" icon>
            <v-badge :value="unreadMessagesCount" :content="unreadMessagesCount" color="primary" overlap>
              <v-icon>mdi-message-text-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        Send a Message
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-1" icon v-bind="attrs" v-on="on" @click="createNewNote()">
            <v-icon class="mt-n1">mdi-file-document-box-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>Add Chart Note</span>
      </v-tooltip>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import bus from '../core/helpers/bus'
import PatientActionBarExportFacesheetMenu from './PatientActionBarExportFacesheetMenu.vue'

export default {
  components: {
    PatientActionBarExportFacesheetMenu,
  },
  data() {
    return {
      menu: false,
      refPoint: null,
      showAlert: false,
      alertInterval: null,
      alertShown: 0,
      alertForDisplay: null,
    }
  },
  props: {
    elementColor: null,
    collapsable: {
      type: Boolean,
      default: true,
    },
    includeTimer: {
      type: Boolean,
      default: true,
    },
    enablePopups: {
      type: Boolean,
      default: true,
    },
    justify: {
      type: String,
      default: 'end',
    },
    patient: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState('conversation', ['unreadMessagesCount']),
    collapsed() {
      if (!this.collapsable) {
        return false
      }
      // if its medium then collapse it
      if (this.$vuetify.breakpoint.mdAndDown) {
        return true
      }
      return false
    },
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    createNewNote() {
      bus.$emit('newNote')
    },
    handleMessengerClick() {
      if (this.patient.smsPhone) {
        bus.$emit('messenger:open', { patient: this.patient })
      } else {
        bus.$emit('smssnack:show', this.patient)
      }
    },
  },
}
</script>

<style></style>
